import {Divider} from "../../../UI/Divider";
import styles from './Body.module.scss'
import {game} from "../../../../Api";
import React from "react";
import {TasksBody} from "./Tasks";
import {getter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE} from "../../../../state/elum";
import {body} from "./texts";
import clsx from "clsx";
import {TappAdsBody} from "./TappAdsBody";

export type ITask = {
    "id": number,
    "title": string,
    "icon": string,
    description: string
    payload: string
    award: number
    btn_label: string
}

export const EarnBody = () => {
    const nullArray = Array.from({length: 10}, () => undefined)
    const [tasks, setTasks] = React.useState<ITask[] | undefined[]>(nullArray);
    const [tappAdsTasks, setTapAdsTasks] = React.useState<ITask[]>([]);
    const langCode = getter(LANGUAGE_CODE)
    const anchor = useGlobalValue(ANCHOR)


    const getTasks = async () => {
        try {
            const {data} = await game.tasks.get('earn')
            const taskWithId8 = data.find((task: any) => task.id === 8);
            const taskWithId18 = data.find((task: any) => task.id === 18);
            const taskWithId34 = data.find((task: any) => task.id === 34);
            const taskWithId48 = data.find((task: any) => task.id === 48);
            const taskWithId52 = data.find((task: any) => task.id === 52);
            const taskWithId53 = data.find((task: any) => task.id === 53);
            const taskWithId54 = data.find((task: any) => task.id === 54);
            const taskWithId64 = data.find((task: any) => task.id === 64);
            const taskWithId72 = data.find((task: any) => task.id === 72);
            const taskWithId77 = data.find((task: any) => task.id === 77);
            const taskWithId90 = data.find((task: any) => task.id === 90);
            const taskWithId94 = data.find((task: any) => task.id === 94);
            const taskWithId104 = data.find((task: any) => task.id === 104);
            const taskWithId114 = data.find((task: any) => task.id === 114);
            const taskWithId115 = data.find((task: any) => task.id === 115);
            const taskWithId116 = data.find((task: any) => task.id === 116);

            const filteredData = data.filter((task: any) =>
                task.id !== 8 &&
                task.id !== 18 &&
                task.id !== 34 &&
                task.id !== 48 &&
                task.id !== 52 &&
                task.id !== 53 &&
                task.id !== 54 &&
                task.id !== 64 &&
                task.id !== 72 &&
                task.id !== 77 &&
                task.id !== 18 &&
                task.id !== 90 &&
                task.id !== 94 &&
                task.id !== 104 &&
                task.id !== 114 &&
                task.id !== 115 &&
                task.id !== 116
            );

                let reorderedData = [...filteredData];
                if (taskWithId18) {
                    reorderedData = [taskWithId18, ...reorderedData];
                }
                if (taskWithId34) {
                    reorderedData = [taskWithId34, ...reorderedData];
                }
                if (taskWithId52) {
                    reorderedData = [taskWithId52, ...reorderedData];
                }
                if (taskWithId53) {
                    reorderedData = [taskWithId53, ...reorderedData];
                }
                if (taskWithId54) {
                    reorderedData = [taskWithId54, ...reorderedData];
                }
                if (taskWithId64) {
                    reorderedData = [taskWithId64, ...reorderedData];
                }
                if (taskWithId72) {
                    reorderedData = [taskWithId72, ...reorderedData];
                }
                if (taskWithId77) {
                    reorderedData = [taskWithId77, ...reorderedData];
                }
                if (taskWithId48) {
                    reorderedData = [taskWithId48, ...reorderedData];
                }
                if (taskWithId104) {
                    reorderedData = [taskWithId104, ...reorderedData];
                }
                if (taskWithId90) {
                    reorderedData = [taskWithId90, ...reorderedData];
                }
                if (taskWithId8) {
                    reorderedData = [taskWithId8, ...reorderedData];
                }
                if (taskWithId94) {
                    reorderedData = [taskWithId94, ...reorderedData];
                }
                if (taskWithId114) {
                    reorderedData = [taskWithId114, ...reorderedData];
                }
                if (taskWithId116) {
                    reorderedData = [taskWithId116, ...reorderedData];
                }
                if (taskWithId115) {
                    reorderedData = [taskWithId115, ...reorderedData];
                }
                setTasks(reorderedData);
        } catch (e) {
            console.log(e)
        }
    }

    const getTappTasks = async () => {
        try {
            const {data} = await game.tasks.get('tap')
            setTapAdsTasks(data)
        } catch (e) {

        }
    }


    React.useEffect(() => {
        getTasks()
        getTappTasks()
    },[anchor])

    return (
        <div className={styles.body}>
            {
                tappAdsTasks.length > 0 &&
                <>
                    <Divider>Special tasks</Divider>
                    <div className={clsx([styles.body_tasks, 'fade-in'])}>
                        <TappAdsBody tasks={tappAdsTasks}/>
                    </div>
                </>
            }
            <Divider>{body.title[langCode]}</Divider>

            <div className={styles.body_tasks}>
                <TasksBody tasks={tasks}/>
            </div>
        </div>
    )
}
