import {SnackbarCloseButton} from "../../Engine/Hooks/useSnackbar";
import React from "react";
import {SnackbarProvider} from "notistack";
import {setter, useGlobalValue} from "elum-state/react";
import {THEME, BANNED, USER, SHOWED_SPECIAL_TASK, SIDEBARS, MODALS} from "../../Engine/state/elum";
import {game} from "../../Engine/Api";
import {API_URL} from "../../Engine/Api/config";
import CryptoJS from "crypto-js";
import TonConnect from '@tonconnect/sdk';
import {useTonAddress} from "@tonconnect/ui-react";
import clsx from "clsx";
import styles from "./MainWrapper.module.scss"
import {BannedWrapper} from "../../Engine/Components/wrappers/banned";
import {AppRouter} from "../Router";

export const connector = new TonConnect({
    manifestUrl: '/tonconnect-manifest.json',
});


export const MainWrapper = () => {
    const theme = useGlobalValue(THEME)
    const userFriendlyAddress = useTonAddress();
    const user = useGlobalValue(USER)
    const showedSpecialTask = useGlobalValue(SHOWED_SPECIAL_TASK)
    const getUser = async () => {
        try {
            const {data} = await game.users.get()

            if (data.id) {
                const photo = API_URL + `/users.photo/${data.id}`
                const time = +CryptoJS.AES.decrypt(data.time, String(data.tid)).toString(CryptoJS.enc.Utf8)

                if (time < Date.now() / 1000) {
                    setter(BANNED, 'time_end')
                }
                setter(USER, {...data, photo, is_loaded: true, time})
                fetch(photo)
            }

            if (!showedSpecialTask && !data.special_task_state_2) {
                setter(SHOWED_SPECIAL_TASK, true)
                setter(MODALS, 'special_task')
            }


        } catch (e) {

        } finally {
        }
    }


    const syncUser = () => {
        if (user.is_loaded) {
            if (!user.ton_address && userFriendlyAddress !== '') {
                game.users.setAddress(userFriendlyAddress)
            }
        }
    }


    React.useEffect(() => {
        getUser()
    },[])

    React.useEffect(() => {
        syncUser()
    },[user.is_loaded, userFriendlyAddress])


    return (
        <SnackbarProvider maxSnack={1} autoHideDuration={1500}
                          classes={{
                              root: 'custom-snackbar',
                          }}
                          action={(key) => <SnackbarCloseButton snackbarKey={key} />} >
            <BannedWrapper>
                <div className={clsx(['center', theme, styles.main ])}>
                    <AppRouter/>
                </div>
            </BannedWrapper>
        </SnackbarProvider>
    )
}
