import {api} from "./config";

class ApiClass {
    users: Users
    friends: Friends
    bonus: Bonus
    tasks: Tasks
    pay: Pay
    ads: Ads
    promo: Promocodes
    transaction: Transaction
    constructor() {
        this.users = new Users()
        this.friends = new Friends()
        this.bonus = new Bonus()
        this.tasks = new Tasks()
        this.pay = new Pay()
        this.ads = new Ads()
        this.promo = new Promocodes()
        this.transaction = new Transaction()
    }
}

class Users {
    private url: string
    constructor() {
        this.url = 'users'
    }

    get () {
        const hand = `${this.url}.get`
        return api.get(hand)
    }
    getMyPosition () {
        const hand = `${this.url}.my.rating`
        return api.get(hand)
    }
    sync (fields: string[]) {
        const hand = `${this.url}.sync?fields=${fields.join(',')}`
        return api.get(hand)
    }
    auth () {
        const hand = `${this.url}.auth`
        return api.get(hand)
    }
    reg() {
        const hand = `${this.url}.reg`
        return api.get(hand)
    }
    getAllUsers() {
        const hand = `${this.url}.all`
        return api.get(hand)
    }
    getTop() {
        const hand = `${this.url}.top`
        return api.get(hand)
    }
    setAddress(address: string) {
        const hand = `${this.url}.set.address/${address}`
        return api.get(hand)
    }
}

class Friends {
    private url: string
    constructor() {
        this.url = 'friends'
    }

    get() {
        const hand = `${this.url}.get`
        return api.get(hand)
    }

}

class Bonus {
    private url: string
    constructor() {
        this.url = 'bonus'
    }

    get() {
        const hand = `${this.url}.get`
        return api.get(hand)
    }

    collect() {
        const hand = `${this.url}.collect`
        return api.get(hand)
    }

}
class Tasks {
    private url: string
    constructor() {
        this.url = 'tasks'
    }

    get(type: string) {
        const hand = `${this.url}.get?type=${type}`
        return api.get(hand)
    }
    getById(id: string) {
        const hand = `${this.url}.get/${id}`
        return api.get(hand)
    }
    check(id: string, is_open_app = false) {
        const hand = `${this.url}.check/${id}` + (is_open_app ? `?open_app=${is_open_app}` : '')
        return api.get(hand)
    }
    click(offer_id: number) {
        const hand = `${this.url}.click?id=${offer_id}`
        return api.get(hand)
    }

}

class Pay {
    private url: string
    constructor() {
        this.url = 'pay'
    }

    get(id: number) {
        const hand = `${this.url}.get?id=${id}`
        return api.get(hand)
    }

}


class Ads {
    private url: string
    constructor() {
        this.url = 'ads'
    }

    prepare() {
        const hand = `${this.url}.prepare`
        return api.get(hand)
    }

    check(hash: string) {
        const hand = `${this.url}.check`
        return api.get(hand, {
            headers: {
                "Content-sign": hash
            }
        })
    }

}
class Promocodes {
    private url: string
    constructor() {
        this.url = 'promocodes'
    }

    check(code: string) {
        const hand = `${this.url}.check?code=${code}`
        return api.get(hand)
    }

}

class Transaction {
    private url: string
    constructor() {
        this.url = 'transaction'
    }
    make(data:{boc: string}) {
        const hand = `${this.url}.make`
        return api.post(hand, data)
    }
}

const game = new ApiClass()

export {game}
