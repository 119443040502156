import {DailyBonus} from "./DailyBonus";
import {useGlobalValue} from "elum-state/react";
import {SIDEBARS} from "../../../state/elum";
import {TappTask, Task} from "./Task";
import {Donuts} from "./Donuts";
import {AddTime} from "./AddTime";
import {SpecialTask} from "./SpecialTask";

const sidebars = {
    daily_bonus: <DailyBonus/>,
    task: <Task/>,
    donuts: <Donuts/>,
    add_time: <AddTime/>,
    special_task: <SpecialTask/>,
    tapp_task: <TappTask/>,
    '': <></>
}

export const Sidebars = () => {
    const sidebar = useGlobalValue(SIDEBARS)

    return (
        <>
            {sidebars[sidebar]}
        </>

    )
}
