import axios from "axios";
import {encryptedData,  version} from "./config.search";

type IAppMode = 'dev' | 'prod'


export const APP_MODE = 'prod' as IAppMode
export let API_URL = ''
let tgLink = 'https://t.me/TimeGameTestBot/game'

export const manifest = window.location.origin + '/tonconnect-manifest.json'

switch (APP_MODE) {
    case 'dev':
        API_URL = 'http://127.0.0.1:3001'
        //API_URL = 'http://tunneling.bayonetta.ru:3001'
        break
    case 'prod':
        API_URL = 'https://api.thetime.pro'
        tgLink = 'https://t.me/TheTimeGame_bot/game'
        break

}

const api = axios.create({
    baseURL: API_URL
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = encryptedData
    config.headers.version = version
    return config
})



export { api, tgLink}
