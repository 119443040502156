import React from 'react';
import Lottie from 'lottie-react';
import animationData from './Voltage.json';

const LottieAnimation = () => {
    return (
        <div style={{ width: '30px', height: '30px' }}>
            <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default LottieAnimation;