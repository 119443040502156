import React, { useState } from 'react';
import { Modal} from 'antd';
import {setter, useGlobalValue} from "elum-state/react";
import {MODALS, USER} from "../../../../state/elum";
import styles from './SpecialTask.module.scss'
import { Meteors } from '../../Effects/meteors';
import {BalanceDiamond, Hot} from "../../../../../Assets/icons";
import {Button} from "../../Buttons";
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {game} from "../../../../Api";

export const SpecialTask: React.FC = () => {
    const modalOpen = useGlobalValue(MODALS) === 'special_task'
    const [isModalOpen, setIsModalOpen] = useState(modalOpen);


    const classNames = {
        content: styles.modal_content,
        header: styles.modal_header,
        wrapper: styles.modal_wrapper,
        mask: styles.modal_mask,

    }


    const handleCancel = () => {
        setIsModalOpen(false);
        setTimeout(() => setter(MODALS, ''), 300)
    };

    const userFriendlyAddress = useTonAddress();
    const { state, open: openTon, close } = useTonConnectModal();
    const [tonConnectUI, setOptions] = useTonConnectUI();

    const make = async () => {


        if (userFriendlyAddress === '') {
            handleCancel()
            return openTon()
        }

        const paymentParams = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
                {
                    address: 'UQA7Ec2g32NFV4b6sBLBeaFiEm0eo5kXxdm-ReSjEf-mL5TN',
                    amount: '500000000',
                },
            ],
        };

        try {
            const data = await tonConnectUI.sendTransaction(paymentParams);
            if (data.boc) {
                handleCancel()
                return game.transaction.make(data)
            }
        } catch (e) {
            console.log(e)
        }


    }


    return (
        <Modal
            open={isModalOpen}
            classNames={classNames}
            className={styles.modal}
            centered={true}
            onCancel={handleCancel}
            footer={[
                <></>
            ]}
        >

            <div className="">
                <div className=" w-full relative max-w-xs">
                    <div
                        className="absolute inset-0 h-full w-full bg-gradient-to-r from-blue-500 to-teal-500 transform scale-[0.80] bg-red-500 rounded-full blur-3xl"/>
                    <div
                        className={styles.block}>

                        <div className={styles.block_icon}>
                            <Hot/>
                        </div>

                        <div className={styles.block_text}>
                            <p>
                                New special task
                            </p>
                            <p>
                                +100000 min. and 90 rubies!
                            </p>
                        </div>

                        <Button.CometBorder className={styles.block_btn} onClick={make}>
                            <BalanceDiamond/>
                            <p>
                                GOT IT
                            </p>
                            <BalanceDiamond/>
                        </Button.CometBorder>


                        <Meteors number={20}/>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
